@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

.desktop {
  background-color: transparent !important;
}

.mobile-nav-trigger {
  background-color: transparent !important;
}

